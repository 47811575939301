<template>
  <NaiveConfigProvider>
    <NDialogProvider>
      <WhatsNewDialog />
      <SpeedInsights />
      <div>
        <VtDialog
          v-if="showMigrationWarning"
          v-model:open="showMigrationWarning"
          :title-text="t('errors.migrationWarningTitle')"
        >
          <!-- eslint-disable-next-line vue/no-v-html -- it's ok, it comes from the translations so it's safe -->
          <div class="mb-8" v-html="t('errors.migrationWarningHtml')" />
          <VtButton
            type="button"
            intent="primary"
            :text="t('buttons.dismissMigrationWarning')"
            @click="dismissWarning"
          />
        </VtDialog>
        <NuxtPage />
      </div>
    </NDialogProvider>
  </NaiveConfigProvider>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

import { SpeedInsights } from '@vercel/speed-insights/nuxt'
import { NDialogProvider } from 'naive-ui'

import { NuxtPage } from '#components'
import { useI18n } from '#i18n'
import { useRoute } from '#imports'

import { VtDialog, VtButton } from '@visiontree/vue-ui'

import NaiveConfigProvider from '@/components/NaiveConfigProvider.vue'
import WhatsNewDialog from '@/components/WhatsNewDialog.vue'
import { useColorMode } from '@/composables/useColorMode'
import { useUserPreferences } from '@/composables/useUserPreferences'
import { setBodyFontSize } from '@/utils/userPreferences'

const route = useRoute()
const preferences = useUserPreferences()

useColorMode()

const { t } = useI18n()

const showMigrationWarning = ref(
  window.location.hostname.includes('medicalformsx.com') &&
    route.name !== 'preview-id' &&
    sessionStorage.getItem('domainMigrationWarningDismissed') !== 'true',
)

const dismissWarning = () => {
  sessionStorage.setItem('domainMigrationWarningDismissed', 'true')
  showMigrationWarning.value = false
}

watch(
  () => preferences.value.fontSize,
  (sizePreference) => {
    setBodyFontSize(sizePreference)
  },
  { immediate: true },
)
</script>
